<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group
      label="Department"
      @click.native.stop
    >
      <v-select
        v-model="departmentFilterValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        placeholder="Select from list"
        multiple
        :options="departmentOptions"
        :reduce="dept => dept._id"
      />
    </b-form-group>
    <div v-if="$route.name !== 'directory-roles-show'">
      <hr class="dividerHR filter-divider">
      <b-form-group
        label="Role"
        @click.native.stop
      >
        <v-select
          v-model="roleFilterValue"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="role"
          placeholder="Select from list"
          :options="roleOptions"
          :reduce="role => role._id"
        />
      </b-form-group>
      <hr class="dividerHR filter-divider">
    </div>
    <b-form-group label="Status">
      <b-form-radio-group
        v-model="statusFilterValue"
        :options="statusRadioOptions"
        name="status-radios-stacked"
        stacked
      />
    </b-form-group>
    <b-button
      variant="outline-primary"
      size="sm"
      class="mr-1"
      @click="clearFilter"
    >
      Clear All
    </b-button>
    <b-button
      variant="primary"
      size="sm"
      @click="applyFilter"
    >
      Apply Filter
    </b-button>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BFormRadioGroup, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BFormRadioGroup,
    BRow,
    BCol,
    vSelect,
  },
  props: {
    statusFilter: {
      type: [String, null],
      default: null,
    },
    roleFilter: {
      type: [String, null],
      default: null,
    },
    departmentFilter: {
      type: [Array, null],
      default: null,
    },
    statusRadioOptions: {
      type: Array,
      required: true,
    },
    departmentOptions: {
      type: Array,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      roleFilterValue: this.roleFilter,
      statusFilterValue: this.statusFilter,
      departmentFilterValue: JSON.parse(JSON.stringify(this.departmentFilter)) || [],
    }
  },
  watch: {
    roleFilter: {
      immediate: true,
      handler(val) {
        this.roleFilterValue = val
      },
    },
    statusFilter: {
      immediate: true,
      handler(val) {
        this.statusFilterValue = val
      },
    },
    departmentFilter: {
      immediate: true,
      handler(val) {
        this.departmentFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
  },
  methods: {
    applyFilter() {
      this.$emit('update:roleFilter', this.roleFilterValue)
      this.$emit('update:departmentFilter', this.departmentFilterValue)
      this.$emit('update:statusFilter', this.statusFilterValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.roleFilterValue = ''
      this.statusFilterValue = null
      this.departmentFilterValue = []
      this.$emit('update:roleFilter', this.roleFilterValue)
      this.$emit('update:departmentFilter', this.departmentFilterValue)
      this.$emit('update:statusFilter', this.statusFilterValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
